import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import fortune from './modules/fortune'

import share from '../share'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

const localStoragePlugin = store => {
  store.subscribe((mutation, { user }) => {
    if (mutation.type === 'user/setUserInfo') {
      window.localStorage.setItem('user', JSON.stringify(user))
    }
  })
}

export default new Vuex.Store({
  state: {
    initialized: false
  },
  getters: {},
  mutations: {
    setInitialized(state) {
      state.initialized = true
    }
  },
  actions: {
    async init({ dispatch, commit }, payload) {
      const login = async function () {
        if (localStorage.getItem('user') === null) {
          if (share.isWeixin()) {
            if (typeof payload.ttxid === 'undefined') {
              await dispatch('user/wxLogin')
            } else {
              commit('user/setUserInfo', payload)
            }
          } else {
            await dispatch('user/guestLogin')
          }
        } else {
          const now = new Date()
          const user = JSON.parse(localStorage.getItem('user'))
          if (now.getTime() - user.timestamp > 15552000000) {
            localStorage.clear()
            await login()
          } else {
            commit('user/setUserInfo', user)
          }
        }
      }

      await login()

      dispatch('fortune/getHistory', user.state)
      dispatch('fortune/getChannelData', payload)

      try {
        TtxTrack.setId(this.state.user.ttxid)
      } catch (error) { }

      commit('setInitialized')
    }
  },
  modules: { user, fortune },
  plugins: [localStoragePlugin],
  strict: debug
})
