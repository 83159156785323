import axios from 'axios'

import { FORTUNE_GETTERS } from './fortune-getters-types'
import { FORTUNE_MUTATION } from './fortune-mutation-types'
import { FORTUNE_ACTIONS } from './fortune-actions-types'

const ENDPOINT = 'https://api.taotaoxi.cn'

const HISTORY_PATH = '/fortune/he-pan/history'
const INPUT_DATA_PATH = '/fortune/he-pan/input-data'
const SIMPLE_RESULT_PATH = '/fortune/he-pan/simple-result'
const RESULT_PATH = '/fortune/he-pan/result'

// const SOLAR_TO_LUNAR_PATH = '/util/lunar-date'

const CHANNEL_JSON_ADDRESS = 'https://shared-resource.taotaoxi.net/frontend/json/'

const state = {
  channel: 'ttx',
  count: 479822,
  data: {
    name: 'hePan',
    weChatId: '273001',
    weChatH5Id: '273201',
    price: '68.8',
    priceH5: '68.8'
  },
  request: {},
  loadingCount: 0,
  history: [],
  result: {}
}

const getters = {
  [FORTUNE_GETTERS.IS_NOT_EMPTY]: state => {
    return Array.isArray(state.history) && state.history.length
  },
  [FORTUNE_GETTERS.LATEST_QUIZ]: state => {
    return state.history[0]
  },
  [FORTUNE_GETTERS.SEARCH]: state => {
    return imageId => {
      return state.result[imageId]
    }
  },
  [FORTUNE_GETTERS.IS_LOADING]: state => {
    return state.loadingCount !== 0
  }
}

// mutations
const mutations = {
  [FORTUNE_MUTATION.SET_HISTORY](state, payload) {
    state.history = payload.data.quizHistory
  },
  [FORTUNE_MUTATION.SET_RESULT](state, payload) {
    if ('undefined' === typeof state.result[payload.orderNo]) {
      state.result = { ...state.result, [payload.orderNo]: payload.data }
    }
  },
  [FORTUNE_MUTATION.SET_COUNT](state, payload) {
    state.count = payload.count
  },
  setRequestData(state, payload) {
    state.request = payload
  },
  setChannelData(state, payload) {
    if (typeof payload[state.data.name] !== 'undefined') {
      state.channel = payload.channel
      var channelData = payload[state.data.name]
      state.data.weChatId = channelData.weChatId
      state.data.weChatH5Id = channelData.weChatH5Id
      state.data.price = channelData.price
      state.data.priceH5 = channelData.priceH5
    }
  },
  [FORTUNE_MUTATION.ADD_LOADING](state) {
    state.loadingCount++
  },
  [FORTUNE_MUTATION.MINUS_LOADING](state) {
    state.loadingCount--
  }
}

const actions = {
  async [FORTUNE_ACTIONS.GET_HISTORY]({ commit }, payload) {
    commit(FORTUNE_MUTATION.ADD_LOADING)
    var postData = {}
    postData.ttxid = payload.ttxid
    postData.timestamp = payload.timestamp
    postData.token = payload.token
    try {
      let res = await axios.get(ENDPOINT + HISTORY_PATH, { params: postData })
      commit(FORTUNE_MUTATION.SET_HISTORY, res)
    } catch (err) {}
    commit(FORTUNE_MUTATION.MINUS_LOADING)
  },
  async [FORTUNE_ACTIONS.GET_SIMPLE_RESULT]({ commit }, payload) {
    commit(FORTUNE_MUTATION.ADD_LOADING)
    payload = { ...payload, ...state.request }

    try {
      let res = await axios.get(ENDPOINT + SIMPLE_RESULT_PATH, {
        params: payload
      })
      return res.data
    } catch (err) {
      throw err
    } finally {
      commit(FORTUNE_MUTATION.MINUS_LOADING)
    }
  },
  async [FORTUNE_ACTIONS.GET_RESULT]({ commit }, payload) {
    commit(FORTUNE_MUTATION.ADD_LOADING)
    try {
      let res = await axios.get(ENDPOINT + RESULT_PATH, {
        params: payload
      })
      commit(FORTUNE_MUTATION.SET_RESULT, {
        orderNo: payload.orderNo,
        data: res.data
      })
    } catch (err) {
      throw err
    } finally {
      commit(FORTUNE_MUTATION.MINUS_LOADING)
    }
  },
  async getLunarDate({ commit }, payload) {
    commit(FORTUNE_MUTATION.ADD_LOADING)
    try {
      let res = await axios.get(ENDPOINT + SOLAR_TO_LUNAR_PATH, {
        params: payload
      })
      commit(FORTUNE_MUTATION.MINUS_LOADING)
      return res.data
    } catch (err) {
      commit(FORTUNE_MUTATION.MINUS_LOADING)
      throw err
    }
  },
  async [FORTUNE_ACTIONS.GET_COUNT]({ commit }) {
    commit(FORTUNE_MUTATION.SET_COUNT, {
      count: 479823
    })
  },
  async [FORTUNE_ACTIONS.GET_CHANNEL_DATA]({ commit }, payload) {
    commit(FORTUNE_MUTATION.ADD_LOADING)
    if (typeof payload.channel !== 'undefined') {
      axios.get(CHANNEL_JSON_ADDRESS + payload.channel + '.json').then(res => {
        commit(FORTUNE_MUTATION.SET_CHANNEL_DATA, {
          ...res.data,
          channel: payload.channel
        })
      })
    }
    commit(FORTUNE_MUTATION.MINUS_LOADING)
  },
  async [FORTUNE_ACTIONS.POST_QUIZ_DATA]({ commit }, payload) {
    commit(FORTUNE_MUTATION.ADD_LOADING)

    let requestData = { ...state.request, orderNo: payload.orderNo }

    await axios.post(
      ENDPOINT +
        INPUT_DATA_PATH +
        '?ttxid=' +
        payload.ttxid +
        '&timestamp=' +
        payload.timestamp +
        '&token=' +
        payload.token,
      requestData
    )
    commit(FORTUNE_MUTATION.MINUS_LOADING)
  }
}

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations
}
